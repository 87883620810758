<template>
  <div v-if="permission.project.access">
    <b-card class="card card-congratulations">
      <b-card-text>
        <div class="row align-items-center justify-content-between">
          <div class="col-md-6">
            <h2 class="text-white">{{ project.title }}</h2>
          </div>
          <div class="col-md-3 my-1">
            <div
              class="design-planning-wrapper d-flex text-center justify-content-between align-items-center"
            >
              <div class="design-planning mr-2">
                <p class="card-text mb-25"><b>Start Date</b></p>
                <h6 class="mb-0 text-white">
                  {{ project.start_date | date_time_format_short }}
                </h6>
              </div>
              <div class="design-planning mr-2">
                <p class="card-text mb-25"><b>Due Date</b></p>
                <h6 class="mb-0 text-white">
                  {{ project.end_date | date_time_format_short }}
                </h6>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div
              class="design-planning-wrapper d-flex text-center justify-content-end align-items-center"
            >
              <div class="design-planning">
                <b-badge
                  style="margin-right: 5px"
                  :variant="
                    project.priority == 1
                      ? 'danger'
                      : project.priority == 2
                      ? 'primary'
                      : 'warning'
                  "
                >
                  {{
                    project.priority == 1
                      ? "High Priority"
                      : project.priority == 2
                      ? "Medium Priority"
                      : "Low Priority"
                  }}
                </b-badge>
                <b-badge
                  style="margin-right: 5px"
                  :variant="
                    project.is_progress == 1
                      ? 'warning'
                      : project.is_progress == 2
                      ? 'primary'
                      : project.is_progress == 3
                      ? 'success'
                      : 'danger'
                  "
                >
                  {{
                    project.is_progress == 1
                      ? "Not Started"
                      : project.is_progress == 2
                      ? "On Going"
                      : project.is_progress == 3
                      ? "Finished"
                      : "Cancel"
                  }}
                </b-badge>
              </div>
              <!-- <button class="btn btn-primary btn-sm">
                <feather-icon icon="EditIcon" />
              </button> -->
            </div>
          </div>
        </div>
      </b-card-text>
    </b-card>
    <StatisticsVue :project="project" />
    <div class="row">
      <div class="col-md-4">
        <MemberListVue
          :id="project.id"
          :employees="employees"
          v-if="is_project"
        />
      </div>
      <div class="col-md-8">
        <MainTaskVue
          :id="project.id"
          :departments="project.departments"
          v-if="is_project && permission.main_task.access"
        />
      </div>
      <div class="col-md-8">
        <TaskListVue
          :id="project.id"
          :employees="employees"
          v-if="is_project && permission.task.access"
        />
      </div>
      <div class="col-md-4">
        <ActivitiesVue :id="project.id" v-if="is_project" />
      </div>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BImg,
  BAvatar,
  BCardText,
  BBadge,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BMediaBody,
} from "bootstrap-vue";
import StatisticsVue from "./partials/Statistics.vue";
import MemberListVue from "./partials/MemberList.vue";
import MainTaskVue from "./partials/MainTask.vue";
import TaskListVue from "./partials/TaskList.vue";
import ActivitiesVue from "./partials/Activities.vue";

export default {
  data() {
    return {
      employees: {},
      project: {},
      is_project: false,
    };
  },
  mounted() {
    this.getResult();
    this.getEmployee();
  },
  methods: {
    async getResult() {
      const project = await this.callApi(
        "post",
        "/project/show/" + this.$route.params.id
      );
      if (project.status == 200) {
        this.project = project.data.project;
        this.is_project = true;
      }
    },

    async getEmployee() {
      const employees = await this.callApi(
        "post",
        "/project/get/employees/" + this.$route.params.id
      );
      if (employees.status == 200) {
        this.employees = employees.data.employees;
      }
    },
  },
  computed: {
    permission() {
      return this.$store.getters.getPermissions;
    },
  },
  components: {
    BCard,
    BImg,
    BAvatar,
    BCardText,
    BBadge,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BRow,
    BCol,
    BMedia,
    BMediaAside,
    BMediaBody,
    MemberListVue,
    StatisticsVue,
    MainTaskVue,
    TaskListVue,
    ActivitiesVue,
  },
};
</script>
