var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.permission.project.access)?_c('div',[_c('b-card',{staticClass:"card card-congratulations"},[_c('b-card-text',[_c('div',{staticClass:"row align-items-center justify-content-between"},[_c('div',{staticClass:"col-md-6"},[_c('h2',{staticClass:"text-white"},[_vm._v(_vm._s(_vm.project.title))])]),_c('div',{staticClass:"col-md-3 my-1"},[_c('div',{staticClass:"design-planning-wrapper d-flex text-center justify-content-between align-items-center"},[_c('div',{staticClass:"design-planning mr-2"},[_c('p',{staticClass:"card-text mb-25"},[_c('b',[_vm._v("Start Date")])]),_c('h6',{staticClass:"mb-0 text-white"},[_vm._v(" "+_vm._s(_vm._f("date_time_format_short")(_vm.project.start_date))+" ")])]),_c('div',{staticClass:"design-planning mr-2"},[_c('p',{staticClass:"card-text mb-25"},[_c('b',[_vm._v("Due Date")])]),_c('h6',{staticClass:"mb-0 text-white"},[_vm._v(" "+_vm._s(_vm._f("date_time_format_short")(_vm.project.end_date))+" ")])])])]),_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"design-planning-wrapper d-flex text-center justify-content-end align-items-center"},[_c('div',{staticClass:"design-planning"},[_c('b-badge',{staticStyle:{"margin-right":"5px"},attrs:{"variant":_vm.project.priority == 1
                    ? 'danger'
                    : _vm.project.priority == 2
                    ? 'primary'
                    : 'warning'}},[_vm._v(" "+_vm._s(_vm.project.priority == 1 ? "High Priority" : _vm.project.priority == 2 ? "Medium Priority" : "Low Priority")+" ")]),_c('b-badge',{staticStyle:{"margin-right":"5px"},attrs:{"variant":_vm.project.is_progress == 1
                    ? 'warning'
                    : _vm.project.is_progress == 2
                    ? 'primary'
                    : _vm.project.is_progress == 3
                    ? 'success'
                    : 'danger'}},[_vm._v(" "+_vm._s(_vm.project.is_progress == 1 ? "Not Started" : _vm.project.is_progress == 2 ? "On Going" : _vm.project.is_progress == 3 ? "Finished" : "Cancel")+" ")])],1)])])])])],1),_c('StatisticsVue',{attrs:{"project":_vm.project}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"},[(_vm.is_project)?_c('MemberListVue',{attrs:{"id":_vm.project.id,"employees":_vm.employees}}):_vm._e()],1),_c('div',{staticClass:"col-md-8"},[(_vm.is_project && _vm.permission.main_task.access)?_c('MainTaskVue',{attrs:{"id":_vm.project.id,"departments":_vm.project.departments}}):_vm._e()],1),_c('div',{staticClass:"col-md-8"},[(_vm.is_project && _vm.permission.task.access)?_c('TaskListVue',{attrs:{"id":_vm.project.id,"employees":_vm.employees}}):_vm._e()],1),_c('div',{staticClass:"col-md-4"},[(_vm.is_project)?_c('ActivitiesVue',{attrs:{"id":_vm.project.id}}):_vm._e()],1)])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }