<template>
  <b-card no-body class="card-statistics">
    <b-card-body class="statistics-body">
      <b-row>
        <b-col xl="3" sm="6" class="mb-2 mb-xl-0">
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar size="48" variant="light-primary">
                <feather-icon size="24" icon="UsersIcon" />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ project.total_department }}
              </h4>
              <b-card-text class="font-small-3 mb-0"> Total Team </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
        <b-col xl="3" sm="6" class="mb-2 mb-xl-0">
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar size="48" variant="light-info">
                <feather-icon size="24" icon="UserIcon" />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ project.total_employee }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                Total Member
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
        <b-col xl="3" sm="6" class="mb-2 mb-xl-0">
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar size="48" variant="light-warning">
                <feather-icon size="24" icon="ListIcon" />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ project.total_task }}
              </h4>
              <b-card-text class="font-small-3 mb-0"> Total Task </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
        <b-col xl="3" sm="6">
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar size="48" variant="light-success">
                <feather-icon size="24" icon="CheckSquareIcon" />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ project.total_complete }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                Total Complete Task
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BImg,
  BAvatar,
  BCardText,
  BBadge,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BMediaBody,
} from "bootstrap-vue";
export default {
  props: ["project"],
  components: {
    BCard,
    BImg,
    BAvatar,
    BCardText,
    BBadge,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BRow,
    BCol,
    BMedia,
    BMediaAside,
    BMediaBody,
  },
};
</script>

<style></style>
