<template>
  <div>
    <b-card no-body class="business-card">
      <b-card-header>
        <b-card-title>Main Tasks</b-card-title>
        <feather-icon
          icon="PlusIcon"
          size="20"
          class="cursor-pointer"
          @click="getModel"
          v-if="permission.main_task.create"
        />
      </b-card-header>

      <b-card-body>
        <vue-perfect-scrollbar :settings="60" style="height: 42vh">
          <div class="business-items">
            <div
              class="business-item"
              v-for="(main_task, index) in main_tasks"
              :key="index"
              v-if="main_tasks.length"
            >
              <div class="row align-items-center justify-content-between">
                <div class="col-lg-5 col-12">
                  <div>
                    <b> {{ main_task.title }}</b>
                  </div>
                  <div>
                    <b-badge
                      pill
                      style="margin-right: 5px"
                      :variant="
                        main_task.priority == 1
                          ? 'light-danger'
                          : main_task.priority == 2
                          ? 'light-primary'
                          : 'light-warning'
                      "
                    >
                      {{
                        main_task.priority == 1
                          ? "High Priority"
                          : main_task.priority == 2
                          ? "Medium Priority"
                          : "Low Priority"
                      }}
                    </b-badge>
                    <b-badge
                      pill
                      style="margin-right: 5px"
                      :variant="
                        main_task.is_progress == 1
                          ? 'light-warning'
                          : main_task.is_progress == 2
                          ? 'light-primary'
                          : main_task.is_progress == 3
                          ? 'light-success'
                          : 'light-danger'
                      "
                    >
                      {{
                        main_task.is_progress == 1
                          ? "Not Started"
                          : main_task.is_progress == 2
                          ? "On Going"
                          : main_task.is_progress == 3
                          ? "Finished"
                          : "Cancel"
                      }}
                    </b-badge>
                  </div>
                </div>
                <div class="col-lg-3 col-12 my-1 m-md-0 text-center">
                  <Progress
                    :percent="
                      getPercentage(
                        main_task.total_task,
                        main_task.total_complete
                      )
                    "
                    :stroke-width="13"
                  />
                  <small class="text-muted">
                    {{ main_task.start_date | date_short }} -
                    {{ main_task.end_date | date_short }}
                  </small>
                </div>
                <div
                  class="col-lg-4 col-12 d-flex align-items-center justify-content-end"
                >
                  <div class="mr-1">Task : {{ main_task.total_task }}</div>
                  <div>
                    <b-avatar-group
                      size="32"
                      class="mr-1"
                      v-if="main_task.employees.length > 1"
                    >
                      <!-- :src="
                        require('@/assets/images/portrait/small/avatar-s-20.jpg')
                      " -->
                      <b-avatar
                        v-for="(employee, index) in main_task.employees"
                        :key="index"
                        variant="primary"
                        v-b-tooltip.hover
                        class="pull-up"
                        :title="employee.name"
                      >
                        {{ getDepartmentChar(employee.name) }}
                      </b-avatar>
                    </b-avatar-group>
                    <b-avatar
                      v-if="main_task.employees.length == 1"
                      v-for="(employee, index) in main_task.employees"
                      :key="index"
                      variant="primary"
                      v-b-tooltip.hover
                      class="pull-up mr-1"
                      :title="employee.name"
                    >
                      {{ getDepartmentChar(employee.name) }}
                    </b-avatar>
                  </div>
                  <div>
                    <b-avatar-group
                      size="32"
                      class="mr-1"
                      v-if="main_task.departments.length > 1"
                    >
                      <!-- :src="
                        require('@/assets/images/portrait/small/avatar-s-20.jpg')
                      " -->
                      <b-avatar
                        v-for="(department, index) in main_task.departments"
                        :key="index"
                        variant="info"
                        v-b-tooltip.hover
                        class="pull-up"
                        :title="department.name"
                      >
                        {{ getDepartmentChar(department.name) }}
                      </b-avatar>
                    </b-avatar-group>
                    <b-avatar
                      v-if="main_task.departments.length == 1"
                      v-for="(department, index) in main_task.departments"
                      :key="index"
                      variant="danger"
                      v-b-tooltip.hover
                      class="pull-up mr-1"
                      :title="department.name"
                    >
                      {{ getDepartmentChar(department.name) }}
                    </b-avatar>
                  </div>
                  <button
                    :disabled="main_task.done_percentage >= 100"
                    class="btn btn-primary btn-sm"
                    style="margin-right: 5px"
                    @click="edit(main_task.id)"
                    v-if="permission.main_task.edit"
                  >
                    <feather-icon icon="EditIcon" />
                  </button>
                  <button
                    v-if="permission.task.create"
                    class="btn btn-success btn-sm"
                    @click="getAddTaskModel(main_task.id, main_task.employees)"
                  >
                    <feather-icon icon="PlusIcon" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </vue-perfect-scrollbar>
      </b-card-body>
    </b-card>
    <Modal
      :closable="false"
      :title="(form.id == '' ? 'Create' : 'Update') + ' Main Task'"
      v-model="create_model"
      :mask-closable="false"
      :footer-hide="true"
      width="700"
    >
      <validation-observer ref="loginValidation">
        <b-form
          class="auth-login-form"
          @submit.prevent
          enctype="multipart/form-data"
        >
          <div class="row mb-2">
            <div class="col-md-12">
              <b-form-group label="Task Title" label-for="title">
                <validation-provider
                  #default="{ errors }"
                  name="Task Title"
                  rules="required"
                >
                  <Input
                    name="title"
                    type="text"
                    v-model="form.title"
                    id="title"
                    placeholder="Enter Task Title"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
            <div class="col-md-6">
              <b-form-group label="Start Date" label-for="start_date">
                <validation-provider
                  #default="{ errors }"
                  name="Start Date"
                  rules="required"
                >
                  <flat-pickr
                    v-model="form.start_date"
                    class="form-control"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Select Start Date"
                    :config="{
                      enableTime: true,
                      dateFormat: 'Y-m-d H:i',
                    }"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
            <div class="col-md-6">
              <b-form-group label="End Date" label-for="end_date">
                <validation-provider
                  #default="{ errors }"
                  name="End Date"
                  rules="required"
                >
                  <flat-pickr
                    v-model="form.end_date"
                    class="form-control"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Select End Date"
                    :config="{
                      enableTime: true,
                      dateFormat: 'Y-m-d H:i',
                    }"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
            <div :class="form.done_percentage < 1 ? 'col-md-6' : 'col-md-12'">
              <b-form-group label="Select Priority" label-for="priority">
                <validation-provider
                  #default="{ errors }"
                  name="Priority"
                  rules="required"
                >
                  <Select
                    v-model="form.priority"
                    filterable
                    name="priority"
                    id="priority"
                    placeholder="Select Priority"
                  >
                    <Option :value="1">High</Option>
                    <Option :value="2">Medium</Option>
                    <Option :value="3">Low</Option>
                  </Select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
            <div class="col-md-6" v-if="form.done_percentage < 1">
              <b-form-group label="Is Progress" label-for="is_progress">
                <validation-provider
                  #default="{ errors }"
                  name="is_progress"
                  rules="required"
                >
                  <Select
                    v-model="form.is_progress"
                    filterable
                    name="is_progress"
                    id="is_progress"
                    placeholder="Is Progress"
                  >
                    <Option :value="1">Not Started</Option>
                    <Option :value="2">On Going</Option>
                  </Select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
            <div class="col-md-12">
              <b-form-group label="Select Department" label-for="department_id">
                <validation-provider
                  #default="{ errors }"
                  name="Department"
                  rules="required"
                >
                  <Select
                    multiple
                    @on-change="getEmployee"
                    v-model="form.department_id"
                    filterable
                    name="department_id"
                    id="department_id"
                    placeholder="Select Department"
                  >
                    <Option
                      :value="department.pivot.department_id"
                      v-for="(department, department_index) in departments"
                      :key="department_index"
                      v-if="departments.length"
                      >{{ department.name }}</Option
                    >
                  </Select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
            <div class="col-md-12">
              <b-form-group label="Select Employee" label-for="employee_id">
                <validation-provider
                  #default="{ errors }"
                  name="Employee"
                  rules="required"
                >
                  <Select
                    multiple
                    v-model="form.employee_id"
                    filterable
                    name="employee_id"
                    id="employee_id"
                    placeholder="Select Employee"
                  >
                    <Option
                      :value="employee.id"
                      v-for="(employee, index) in employees"
                      :key="index"
                      v-if="employees.length"
                      >{{ employee.name }}</Option
                    >
                  </Select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
            <div class="col-md-12">
              <b-form-group
                label="Description"
                label-for="description"
                rules="required"
              >
                <validation-provider #default="{ errors }" name="Description">
                  <Input
                    v-model="form.description"
                    type="textarea"
                    :rows="3"
                    placeholder="Enter Your Description"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
          </div>
          <div class="text-right">
            <b-button
              type="submit"
              class="mx-1"
              variant="outline-primary"
              @click="Close"
            >
              Close
            </b-button>
            <b-button type="submit" variant="primary" @click="validationForm">
              {{ form.id == "" ? "Create" : "Update" }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </Modal>
    <Modal
      :title="(form_task.id == '' ? 'Create' : 'Update') + ' Task'"
      v-model="create_task_model"
      :mask-closable="false"
      :footer-hide="true"
      :closable="false"
      width="700"
    >
      <validation-observer ref="taskValidation">
        <b-form
          class="auth-login-form"
          @submit.prevent
          enctype="multipart/form-data"
        >
          <div class="row mb-2">
            <div class="col-md-6">
              <b-form-group label="Task Title" label-for="title">
                <validation-provider
                  #default="{ errors }"
                  name="Task Title"
                  rules="required"
                >
                  <Input
                    name="title"
                    type="text"
                    v-model="form_task.title"
                    id="title"
                    placeholder="Enter Task Title"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
            <div class="col-md-6">
              <b-form-group label="Select Type" label-for="type">
                <validation-provider
                  #default="{ errors }"
                  name="Type"
                  rules="required"
                >
                  <Select
                    v-model="form_task.type"
                    filterable
                    name="type"
                    id="type"
                    placeholder="Select Type"
                  >
                    <Option :value="1">Check List</Option>
                    <Option :value="2">Time Tracker</Option>
                    <Option :value="3">Progressbar</Option>
                    <Option :value="4">Feedback</Option>
                    <Option :value="5">Hour</Option>
                    <Option :value="6">Quantity</Option>
                  </Select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
            <div class="col-md-6">
              <b-form-group label="Task Quantity" label-for="qty">
                <validation-provider
                  #default="{ errors }"
                  name="Task Quantity"
                  :rules="form_task.type == 6 ? 'required' : ''"
                >
                  <InputNumber
                    name="qty"
                    v-model="form_task.qty"
                    id="qty"
                    placeholder="Enter Task Quantity"
                    class="w-100"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
            <div class="col-md-6">
              <b-form-group label="Task Duration (Hour)" label-for="duration">
                <validation-provider
                  #default="{ errors }"
                  name="Task Duration"
                  :rules="form_task.type == 5 ? 'required' : ''"
                >
                  <InputNumber
                    name="duration"
                    v-model="form_task.duration"
                    id="duration"
                    placeholder="Enter Task Duration"
                    class="w-100"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
            <div class="col-md-6">
              <b-form-group label="Start Date" label-for="start_date">
                <validation-provider
                  #default="{ errors }"
                  name="Start Date"
                  rules="required"
                >
                  <flat-pickr
                    v-model="form_task.start_date"
                    class="form-control"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Select Start Date"
                    :config="{
                      enableTime: true,
                      dateFormat: 'Y-m-d H:i',
                    }"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
            <div class="col-md-6">
              <b-form-group label="End Date" label-for="end_date">
                <validation-provider
                  #default="{ errors }"
                  name="End Date"
                  rules="required"
                >
                  <flat-pickr
                    v-model="form_task.end_date"
                    class="form-control"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Select End Date"
                    :config="{
                      enableTime: true,
                      dateFormat: 'Y-m-d H:i',
                    }"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
            <div class="col-md-6">
              <b-form-group label="Select Priority" label-for="priority">
                <validation-provider
                  #default="{ errors }"
                  name="Priority"
                  rules="required"
                >
                  <Select
                    v-model="form_task.priority"
                    filterable
                    name="priority"
                    id="priority"
                    placeholder="Select Priority"
                  >
                    <Option :value="1">High</Option>
                    <Option :value="2">Medium</Option>
                    <Option :value="3">Low</Option>
                  </Select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
            <div class="col-md-6">
              <b-form-group label="Is Progress" label-for="is_progress">
                <validation-provider
                  #default="{ errors }"
                  name="Is Progress"
                  :rules="form_task.id == '' ? 'required' : ''"
                >
                  <Select
                    v-model="form_task.is_progress"
                    filterable
                    name="is_progress"
                    id="is_progress"
                    placeholder="Is Progress"
                  >
                    <Option :value="1">Not Started</Option>
                    <Option :value="2">On Going</Option>
                  </Select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
            <div class="col-md-12">
              <b-form-group label="Select Employee" label-for="employee_id">
                <validation-provider
                  #default="{ errors }"
                  name="Employee"
                  rules="required"
                >
                  <Select
                    multiple
                    v-model="form_task.employee_id"
                    filterable
                    name="employee_id"
                    id="employee_id"
                    placeholder="Select Employee"
                  >
                    <Option
                      :value="employee.pivot.employee_id"
                      v-for="(employee, index) in task_employees"
                      :key="index"
                      v-if="task_employees.length"
                      >{{ employee.name }}</Option
                    >
                  </Select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
            <div class="col-md-12">
              <b-form-group
                label="Description"
                label-for="description"
                rules="required"
              >
                <validation-provider #default="{ errors }" name="Description">
                  <Input
                    v-model="form_task.description"
                    type="textarea"
                    :rows="2"
                    placeholder="Enter Your Description"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
          </div>
          <div class="text-right">
            <b-button
              type="submit"
              class="mx-1"
              variant="outline-primary"
              @click="CloseTask"
            >
              Close
            </b-button>
            <b-button
              type="submit"
              variant="primary"
              @click="validationTaskForm"
            >
              {{ form.id == "" ? "Create" : "Update" }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </Modal>
  </div>
</template>

<script>
import {
  BCardBody,
  BCardHeader,
  BTabs,
  BTab,
  BCard,
  BMediaBody,
  BMediaAside,
  BMedia,
  BFormFile,
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BFormTextarea,
  BFormCheckboxGroup,
  BDropdown,
  BDropdownItem,
  BBadge,
  BAvatar,
  BDropdownDivider,
  VBTooltip,
  BAvatarGroup,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { required } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
import _ from "lodash";

export default {
  props: ["id", "departments"],
  components: {
    BCardHeader,
    BCardBody,
    VuePerfectScrollbar,
    vSelect,
    BCard,
    BCardText,
    BLink,
    BTabs,
    BTab,
    BMedia,
    BFormFile,
    BFormTextarea,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BMediaAside,
    BMediaBody,
    BFormCheckboxGroup,
    BDropdown,
    BDropdownDivider,
    BDropdownItem,
    BAvatarGroup,
    flatPickr,
    BBadge,
    BAvatar,
    VBTooltip,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      employees: {},
      task_employees: {},
      main_tasks: {},
      create_model: false,
      create_task_model: false,
      form: {
        id: "",
        title: null,
        start_date: null,
        end_date: null,
        priority: null,
        assign_status: 1,
        done_percentage: null,
        is_progress: null,
        department_id: [],
        employee_id: [],
        description: null,
      },
      form_task: {
        id: "",
        main_task_id: null,
        default_task_id: null,
        project_id: this.id,
        title: null,
        start_date: null,
        end_date: null,
        priority: null,
        is_progress: null,
        qty: null,
        duration: null,
        type: null,
        employee_id: [],
        description: null,
      },
    };
  },
  mounted() {
    this.getResults();
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  methods: {
    getPercentage(total, complete) {
      if (total > 0) {
        return Math.round((complete / total) * 100);
      } else {
        return 0;
      }
    },
    getDepartmentChar(text) {
      return text
        .replace(/[^a-zA-Z0-9 ]/g, "")
        .split(" ")
        .map((word) => word.charAt(0))
        .join("")
        .toUpperCase()
        .substring(0, 3);
    },
    async getEmployee() {
      const employees = await this.callApi(
        "post",
        "/project/get/employees/" + this.id,
        this.form
      );
      if (employees.status == 200) {
        this.employees = employees.data.employees;
      }
    },
    async getResults() {
      const main_tasks = await this.callApi(
        "post",
        "/project/main/tasks/" + this.id
      );
      if (main_tasks.status == 200) {
        this.main_tasks = main_tasks.data.main_tasks;
      }
    },
    getModel() {
      this.employees = {};
      this.create_model = true;
    },

    Close() {
      this.form.id = "";
      this.form.title = null;
      this.form.start_date = null;
      this.form.end_date = null;
      this.form.priority = null;
      this.form.is_progress = null;
      this.form.done_percentage = null;
      this.form.department_id = [];
      this.form.employee_id = [];
      this.form.description = null;
      this.employees = {};
      this.create_model = false;
    },

    add() {
      axios
        .post("/project/main/tasks/store/" + this.id, this.form)
        .then((res) => {
          this.s(res.data.message);
          this.getResults();
          this.Close();
        })
        .catch((e) => {
          if (e.response.status === 422) {
            if (e.response.data.errors.title)
              this.e(e.response.data.errors.title[0]);
            if (e.response.data.errors.start_date)
              this.e(e.response.data.errors.start_date[0]);
            if (e.response.data.errors.end_date)
              this.e(e.response.data.errors.end_date[0]);
            if (e.response.data.errors.priority)
              this.e(e.response.data.errors.priority[0]);
            if (e.response.data.errors.is_progress)
              this.e(e.response.data.errors.is_progress[0]);
            if (e.response.data.errors.department_id)
              this.e(e.response.data.errors.department_id[0]);
            if (e.response.data.errors.employee_id)
              this.e(e.response.data.errors.employee_id[0]);
            if (e.response.data.errors.description)
              this.e(e.response.data.errors.description[0]);
          }
        });
    },

    async edit(id) {
      const main_task = await this.callApi(
        "post",
        "/project/main/tasks/edit/" + id
      );
      if (main_task.status == 200) {
        this.form.department_id = [];
        this.form.employee_id = [];
        this.form.id = main_task.data.main_task.id;
        this.form.main_task_id = main_task.data.main_task.main_task_id;
        this.form.done_percentage = main_task.data.main_task.done_percentage;
        this.form.assign_status = main_task.data.main_task.assign_status;
        this.form.default_task_id = main_task.data.main_task.default_task_id;
        this.form.title = main_task.data.main_task.title;
        this.form.start_date = main_task.data.main_task.start_date;
        this.form.end_date = main_task.data.main_task.end_date;
        this.form.priority = main_task.data.main_task.priority;
        this.form.is_progress = main_task.data.main_task.is_progress;
        this.form.qty = main_task.data.main_task.qty;
        this.form.duration = main_task.data.main_task.duration;
        this.form.type = main_task.data.main_task.type;
        this.form.description = main_task.data.main_task.description;
        this.getModel();
        let department_id = [];
        main_task.data.main_task.departments.forEach((data) => {
          department_id.push(data.department_id);
        });
        this.form.department_id = department_id;
        this.AssignEmployee(main_task.data.main_task.employees);
      }
    },

    AssignEmployee: _.debounce(function (employees) {
      employees.forEach((data) => {
        this.form.employee_id.push(data.employee_id);
      });
    }, 1000),

    validationForm() {
      this.$refs.loginValidation.validate().then((success) => {
        if (success) {
          this.add();
        }
      });
    },

    addTask() {
      axios
        .post("/project/tasks/store", this.form_task)
        .then((res) => {
          this.s(res.data.message);
          this.$store.dispatch("getProjectTask", this.id);
          this.getResults();
          this.CloseTask();
        })
        .catch((e) => {
          if (e.response.status === 422) {
            if (e.response.data.errors.default_task_id)
              this.e(e.response.data.errors.default_task_id[0]);
            if (e.response.data.errors.main_task_id)
              this.e(e.response.data.errors.main_task_id[0]);
            if (e.response.data.errors.project_id)
              this.e(e.response.data.errors.project_id[0]);
            if (e.response.data.errors.title)
              this.e(e.response.data.errors.title[0]);
            if (e.response.data.errors.start_date)
              this.e(e.response.data.errors.start_date[0]);
            if (e.response.data.errors.end_date)
              this.e(e.response.data.errors.end_date[0]);
            if (e.response.data.errors.priority)
              this.e(e.response.data.errors.priority[0]);
            if (e.response.data.errors.is_progress)
              this.e(e.response.data.errors.is_progress[0]);
            if (e.response.data.errors.qty)
              this.e(e.response.data.errors.qty[0]);
            if (e.response.data.errors.duration)
              this.e(e.response.data.errors.duration[0]);
            if (e.response.data.errors.type)
              this.e(e.response.data.errors.type[0]);
            if (e.response.data.errors.employee_id)
              this.e(e.response.data.errors.employee_id[0]);
            if (e.response.data.errors.description)
              this.e(e.response.data.errors.description[0]);
          }
        });
    },

    getAddTaskModel(main_task_id, employees) {
      this.form_task.main_task_id = main_task_id;
      this.task_employees = employees;
      this.create_task_model = true;
    },

    CloseTask() {
      this.form_task.id = "";
      this.form_task.main_task_id = null;
      this.form_task.title = null;
      this.form_task.start_date = null;
      this.form_task.end_date = null;
      this.form_task.priority = null;
      this.form_task.is_progress = null;
      this.form_task.employee_id = [];
      this.form_task.description = null;
      this.form_task.qty = null;
      this.form_task.duration = null;
      this.form_task.type = null;
      this.task_employees = {};
      this.create_task_model = false;
    },

    validationTaskForm() {
      this.$refs.taskValidation.validate().then((success) => {
        if (success) {
          this.addTask();
        }
      });
    },
  },
  computed: {
    permission() {
      return this.$store.getters.getPermissions;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
